import React from "react";

export interface eventRes {
    Event: event,
    DisplayName: string,
    GraphId: string
}

export interface userRes {
    Id_User: number,
    DisplayName: string,
    GraphId: string,
    YearlyPaidDays: number,
    UsedPaidDays: number,
    UsedOnDemandDays: number,
    UsedUnpaidDays: number,
    EarnedPaidDays: number,
    HasPaidLeave: boolean,
    OnDemandDays: number,
    Email: string
}

export interface loginForm {
    password?: string,
    repeatPassword?: string,
    email?: string
    user?: string
}

export interface statusDialog {
    onClose: any,
    row: eventRes
}

export interface loginOption {
    onClose: any,
    selectUser?: boolean,
    title: string,
    Submit: any
}

export interface dialog {
    onClose: any,
    user: currentUser|null,
    language: any,
    action: string,
    row?:eventRes
}

export interface events {
    id: number,
    title: string,
    start: Date,
    end: Date,
    allDay: boolean,
    description: string,
    id_Type: number
}

export interface currentUserContext {
    user:currentUser|null,   
    message:string,
    setUser:React.Dispatch<React.SetStateAction<currentUser>>|React.Dispatch<React.SetStateAction<null>>,
    setMessage:React.Dispatch<React.SetStateAction<string>>
}

export interface userOptions{
    handleLanguage:any,
    language:string,
    theme?: string,
    setTheme?:any,
    unauthenticated?:boolean
}

export interface avatarGenerator{
    name:string,
    size?:string
}

export interface mobileTable{
    events:eventRes[],
    IconMethod:any,
    count:number,
    page:number,
    setPage:any,
    admin?:boolean,
}


export interface currentUser {
        Login: {
            Email: string,
            PasswordHash: string,
            Id_User: number,
            Role: string,
            Id_Login: number
        },
        User: userRes   
}

export interface userPanelOpen{
    open:boolean,
    action:string,
    row?:eventRes
}

export interface adminStats{
    sumBasic:number,
    sumPaid:number,
    sumOnDemand:number
}

export interface snackbar{
    message:string,
    type:'error'| 'info'| 'success'| 'warning',
    open:boolean
}

export interface userAnchor{
    anchor:any,
    row:eventRes|undefined
}


interface event {
    Description: string,
    StartDate: string,
    EndDate: string,
    IsPaid: boolean,
    Id_Event: number,
    Id_Type: number,
    Id_Status: number,
    Reason: string | null,
}




export const vacationType ={
    All: 0,
    Basic: 1,
    Paid: 2,
    OnDemand: 3,
    Extended: 4
}

export const vacationTypeMain ={
    All: 0,
    Basic: 1,
    Paid: 2,
    OnDemand: 3
}

export const vacationStatus = {
    All: 0,
    Waiting: 1,
    Accepted: 2,
    Rejected: 3,
   // Extension: 4,
    Resign:5,
    Completed:6
}

