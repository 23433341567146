import React, { useEffect, useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Select, MenuItem, FormControl, Typography, ListItemIcon, Button, Divider, Menu } from '@mui/material';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import KeyIcon from '@mui/icons-material/Key';
import { useTranslation, Trans } from 'react-i18next';
import { loginForm, userOptions } from '../components/Models';
import { SnackbarContext } from '../context/SnackbarContext';
import { currentUserContext } from "../components/Models";
import { UserContext } from "../context/UserContext";
import AvatarGenerator from "./AvatarGenerator";
import LoginOptions from './LoginOptions';

const UserOptions = ({ handleLanguage, language, theme, setTheme, unauthenticated = false }: userOptions) => {
    const { user, setUser, message, setMessage } = useContext(UserContext) as currentUserContext;
    const [openLogin, setOpenLogin] = useState<boolean>(false);
    const { t } = useTranslation("", { keyPrefix: 'UserOptions' });
    const { setSnack } = useContext(SnackbarContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const hangeTheme = () => {
        if (theme == 'light') {
            setTheme('dark');
            localStorage.setItem("theme", "dark");
            document.documentElement.setAttribute("data-theme", "dark");
        } else {
            setTheme('light');
            localStorage.setItem("theme", "light");
            document.documentElement.setAttribute("data-theme", "light");
        }
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = async () => {
        localStorage.setItem("IsChecked", "false");
        await fetch(process.env.REACT_APP_API_URL + "/api/login/logout", {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include"
            // @ts-ignore
        }).then(() => setUser(null));
        navigate("/");
    };

    const changePassword = async (data: loginForm, id: number) => {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/login/password", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(
                {
                    "Password": data.repeatPassword,
                    "Id_User": user!.User.Id_User
                }
            ),
            credentials: "include"
        });
        if (response.ok) {
            setSnack({ message: t("Snackbar.success"), type: "success", open: true });
        } else {
            setSnack({ message: t("Snackbar.error"), type: "error", open: true });
        }
        return "";
    };

    useEffect(() => {
        localStorage.setItem("language", language);
    }, [language]);

    const open = Boolean(anchorEl);

    return (
        <>
            {
                openLogin == true ? <LoginOptions onClose={() => setOpenLogin(false)} selectUser={false} title={t("changePassword")} Submit={changePassword} /> : null
            }
            {unauthenticated ?
                <FormControl variant="standard" >
                    <Select onChange={handleLanguage} value={language} disableUnderline  >
                        <MenuItem value={"pl"}>
                            Polski
                        </MenuItem>
                        <MenuItem value={"en"}>
                            English
                        </MenuItem>
                    </Select>
                </FormControl>
                :
                <>
                    <Menu
                        open={open}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        PaperProps={{
                            elevation: 0,
                            className: 'menu',
                            sx: {
                                overflow: 'visible',
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 20,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                },
                            },
                        }}
                    >
                        <MenuItem sx={{ textTransform: "none", "&:hover": { backgroundColor: "transparent" } }} disableRipple={true}>
                            <Typography className='user_info_text' align="right">{user?.User.DisplayName}</Typography>
                        </MenuItem>
                        {user!.Login.Role == "ADMIN" ?
                            <>
                                <Divider />
                                <MenuItem component={Link} to="/AdminPanel">
                                    <ListItemIcon><AdminPanelSettingsRoundedIcon /></ListItemIcon>
                                    {t("adminPanel")}
                                </MenuItem>
                                <MenuItem component={Link} to="/Statistics">
                                    <ListItemIcon><QueryStatsIcon /></ListItemIcon>
                                    {t("statistics")}
                                </MenuItem>
                            </>
                            : null
                        }
                        <Divider />
                        {/* <MenuItem onClick={hangeTheme}>
                <ListItemIcon>{theme === 'light' ? <DarkModeIcon /> : <LightModeIcon/>}</ListItemIcon>                   
                    {theme === 'light' ? "Dark mode" : "Light mode"}
                </MenuItem> */}
                        <MenuItem onClick={() => setOpenLogin(true)}>
                            <ListItemIcon><KeyIcon /></ListItemIcon>
                            {t("changePassword")}
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon><PowerSettingsNewIcon /></ListItemIcon>
                            {t("logout")}
                        </MenuItem>
                    </Menu>

                    <div className="user_options">
                        <FormControl variant="standard" >
                            <Select onChange={handleLanguage} value={language} disableUnderline  >
                                <MenuItem value={"pl"}>
                                    Polski
                                </MenuItem>
                                <MenuItem value={"en"}>
                                    English
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <Button variant="text" className="font" onClick={handleClick} disableRipple >
                            <div className='user_info'>
                                <AvatarGenerator size='big' name={user?.User.DisplayName!} />
                            </div>
                        </Button>

                    </div>
                </>
            }
        </>

    )
}

export default UserOptions;