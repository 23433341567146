import React, { useState, useEffect } from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { InputAdornment, IconButton, TextField, Paper, DialogTitle, DialogContent, Button, Autocomplete, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from "yup";
import { userRes, loginOption } from "../components/Models";

const LoginOptions = ({ onClose, selectUser = true, title, Submit }: loginOption) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [users, setUsers] = useState<userRes[]>([]);
    const [user, setUser] = useState<userRes>();
    const [backMsg, setBackMsg] = useState<string>("");

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const { t } = useTranslation();

    const onSubmit = async (data: any) => {
        const response = await Submit(data, user?.Id_User);
        if (response=="emailTaken") {
            onClose();
        } else {
            setBackMsg(t("Login." + response));
        }
    };

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL+"/api/user/users_short", {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                credentials: "include"
            });
            const res = await response.json();
            setUsers(res);
        };
        if (selectUser) {
            fetchUsers();
        }
    }, [, backMsg]);

    const changePassword = yup.object({
        password: yup.string()
            .required(t("Form.required"))
            .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, t("Login.wrongPassword")),
        repeatPassword: yup.string()
            .required(t("Form.required"))
            .oneOf([yup.ref('password'), ""], t("Login.wrongRepeat")),
    });

    const addLogin = changePassword.shape({
        email: yup.string()
            .required(t("Form.required"))
            .matches(/([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+\.([A-Z|a-z]{2,})/, t("Login.wrongEmail")),
        user: yup.string()
            .required(t("Form.required"))
    });

    const formik = useFormik({
        initialValues: {
            user: '',
            email: '',
            password: '',
            repeatPassword: ''
        },
        validationSchema: selectUser ? addLogin : changePassword,
        onSubmit: (values) => {
            onSubmit(values);
            onClose();
        },
    });

    return (
        <>
            <Dialog open={true} onClose={onClose} className='dialog' classes={{ paper: 'dialog_img' }} fullWidth>
                <DialogTitle><CloseIcon className="close" onClick={onClose}/>
                <Typography variant="h2" align="center">{title}</Typography></DialogTitle>
                <DialogContent className="nopad">
                    <form onSubmit={formik.handleSubmit}>
                        <Paper className='dialog_paper_center center'>
                            {selectUser ?
                                <>
                                    <Autocomplete
                                        fullWidth
                                        options={users}
                                        className="autocomplete"
                                        getOptionLabel={(option) => option.DisplayName}
                                        onChange={(e, value) => {
                                            formik.setFieldValue("user", value?.Id_User);
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params} variant="standard"
                                                value={formik.values.user}
                                                error={formik.touched.user && Boolean(formik.errors.user)}
                                                helperText={formik.touched.user && formik.errors.user}
                                                label={t("Attributes.user") + " *"} />}
                                    />
                                    <TextField variant="outlined" margin="normal"
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label={t("Login.email") + " *"}
                                        className="inputRounded"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </>
                                : null}
                            <TextField variant="outlined" margin="normal"
                                fullWidth
                                label={t("Login.password") + " *"}
                                className="inputRounded"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <TextField variant="outlined" margin="normal"
                                fullWidth
                                label={t("Login.repeatPassword") + " *"}
                                className="inputRounded"
                                name="repeatPassword"
                                type={showPassword ? "text" : "password"}
                                value={formik.values.repeatPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                                helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Typography variant="subtitle1" align="center" sx={{ marginBottom: "10px", color: "red", width: "450px" }}>{t(backMsg)}</Typography>
                            <div className="dialog_submit">
                                <Button type="submit" variant="contained" className="button">
                                    {t("Button.accept")}
                                </Button>
                            </div>
                        </Paper>
                    </form>

                </DialogContent>
            </Dialog>
        </>
    );
}

export default LoginOptions;