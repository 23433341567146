import React, { useEffect, useState, useMemo, useContext } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableFooter, Pagination, TableRow, TableHead, IconButton, Button } from '@mui/material';
import { Select, Menu, MenuItem, FormControl, Typography, Paper, CircularProgress, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { useTranslation } from 'react-i18next';
import { vacationType, vacationStatus, eventRes, currentUserContext, userPanelOpen, userAnchor } from '../../components/Models';
import { days, calcBusinessDaysConv } from '../../components/Methods';
import { SnackbarContext } from '../../context/SnackbarContext';
import { UserContext } from "../../context/UserContext";
import { useView } from '../../context/ViewContext';
import Umbrella from "../../assets/umbrella.svg";
import Case from "../../assets/case.svg";
import Gear from "../../assets/gear.svg";
import Pig from "../../assets/pig.svg";
import Dialog from './Dialog';
import Circle from './Circle';

const UserPanel = (language: any) => {
    const [year, setYear] = useState(new Date().getFullYear());
    const [open, setOpen] = useState<userPanelOpen>({open: false, action: ""});
    const [status, setStatus] = useState<number>(vacationStatus.All);
    const [type, setType] = useState<number>(vacationType.All);
    const [filer, setFilter] = useState({name: "date", direction: "desc"});
    const [eventsAll, setEventsAll] = useState<eventRes[]>([]);
    const [events, setEvents] = useState<eventRes[]>([]);
    const [anchorEl, setAnchorEl] = useState<userAnchor>({anchor: null, row: undefined});
    const [page, setPage] = useState<number>(1);
    const { setSnack } = useContext(SnackbarContext);
    const {user, setUser} = useContext(UserContext) as currentUserContext;
    const {width, view} = useView();
    const {t} = useTranslation();
    const PageSize = 10;
    
    const fetchEvents = async () => {
        var statusEndpoint = "";
        if (status != vacationStatus.All) {
            statusEndpoint = "&status_id=" + status;
        }
        var typeEndpoint = "";
        if (type != vacationType.All) {
            typeEndpoint = "&type_id=" + type;
        }
        const response = await fetch(process.env.REACT_APP_API_URL+"/api/event/search?user_id=" + user!.User.Id_User + statusEndpoint + typeEndpoint, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include"
        });
        const res = await response.json();
        const resYear = res.filter((event: eventRes) => event.Event.StartDate.includes(String(year)));
        let resSort = resYear;
        if (filer.name == "date") {
            if (filer.direction == "asc") {
                resSort = resYear.sort((a: eventRes, b: eventRes) => new Date(a.Event.StartDate).valueOf() - new Date(b.Event.StartDate).valueOf());
            } else {
                resSort = resYear.sort((a: eventRes, b: eventRes) => new Date(b.Event.StartDate).valueOf() - new Date(a.Event.StartDate).valueOf());
            }
        } else if (filer.name == "days") {
            if (filer.direction == "asc") {
                resSort = resYear.sort((a: eventRes, b: eventRes) => calcBusinessDaysConv(a.Event.StartDate, a.Event.EndDate) - calcBusinessDaysConv(b.Event.StartDate, b.Event.EndDate));
            } else {
                resSort = resYear.sort((a: eventRes, b: eventRes) => calcBusinessDaysConv(b.Event.StartDate, b.Event.EndDate) - calcBusinessDaysConv(a.Event.StartDate, a.Event.EndDate));
            }
        }
        setEventsAll(resSort);
        const lastId = page * PageSize;
        const firstId = lastId - PageSize;
        setEvents(resSort.slice(firstId, lastId));
    };

    useEffect(() => {
        fetchEvents();
    }, [, status, year, open, type, page, filer, view]);

    useEffect(() => {
        setPage(1);
    }, [status, year, type]);

    const deleteEvent = async (id: number | undefined) => {
        const response = await fetch(process.env.REACT_APP_API_URL+"/api/event/delete?id_event=" + id, {
            method: "DELETE",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include"
        });
        vacOptions(response);
    };

    const resignEvent = async (id: number | undefined) => {
        const response = await fetch(process.env.REACT_APP_API_URL+"/api/event/resign?id_event=" + id, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include"
        });
        vacOptions(response);
    };

    const vacOptions = (response:any) => {
        fetchEvents();
        if (response.ok) {
            setSnack({ message: t("Snackbar.success"), type: "success", open: true });
        } else {
            setSnack({ message: t("Snackbar.error"), type: "error", open: true });
        }
        setAnchorEl({ anchor: null, row: undefined });
    }

    const handleClose = () => setOpen({ open: false, action: "" });

    const handleFilter = (Name: string) => {
        let Direction = "";
        if ((filer.name != Name) || (filer.name == Name && filer.direction == "asc")) {
            Direction = "desc";
        } else {
            Direction = "asc";
        }
        setFilter({name: Name, direction: Direction});
    }

    const Menuopen = Boolean(anchorEl.anchor);

    return (
        <div>
            {open.open ? <Dialog onClose={handleClose} user={user} language={language} action={open.action} row={open.row != null ? open.row : undefined} /> : null}
            <Paper elevation={2} className='user_info_paper'>
                <div className='user_vac_info'>
                    <div className='vac_info1'>
                        <img src={Umbrella} className="vac_icon center"/>
                        <div className='div_text'>
                            <div>
                                <Typography variant="h4" align="left">{t('Vacation.vacation1')}</Typography>
                                <Typography variant="h4" align="left">{user!.User.UsedUnpaidDays}</Typography>
                            </div>
                            <Circle vac={1} value={user!.User.UsedUnpaidDays} infinite/>
                        </div>
                    </div>
                    <div className='vac_info2'>
                        <img src={Pig} className="vac_icon center"/>
                        <div className='div_text'>
                            <div>
                                <Typography variant="h4" align="left">{t('Vacation.vacation2')}</Typography>
                                <Typography variant="h4" align="left">{Math.floor(user!.User.EarnedPaidDays)!=0? (user!.User.UsedPaidDays+"/"+Math.floor(user!.User.EarnedPaidDays)):t("UserPanel.None")}</Typography>
                            </div>
                            <Circle vac={2}
                                    value={(Math.floor(user!.User.EarnedPaidDays) == 0 ? 0 : user!.User.UsedPaidDays / Math.floor(user!.User.EarnedPaidDays))}/>
                        </div>
                    </div>
                    <div className='vac_info3'>
                        <img src={Case} className="vac_icon center"/>
                        <div className='div_text'>
                            <div>
                                <Typography variant="h4" align="left">{t('Vacation.vacation3')}</Typography>
                                <Typography variant="h4" align="left">{user!.User.OnDemandDays!=0? user!.User.UsedOnDemandDays+"/"+user!.User.OnDemandDays:t("UserPanel.None")}</Typography>
                            </div>
                            <Circle vac={3}
                                    value={(user!.User.OnDemandDays == 0 ? 0 : user!.User.UsedOnDemandDays / user!.User.OnDemandDays)}/>
                        </div>
                    </div>
                </div>
                <Fab onClick={() => {
                    setOpen({open: true, action: "add"})
                }} variant="extended" size="medium" className='fab'>
                    <AddIcon/>
                    <Typography variant='h5'>{t('UserPanel.addVacation')}</Typography>
                </Fab>
            </Paper>
            <Paper elevation={4} className="table_paper_panel center">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>
                                    <FormControl variant="standard">
                                        <Select onChange={(e) => setType(e.target.value as number)} value={type}
                                                className={type != vacationType.All ? "select_color" : "select"}
                                                disableUnderline renderValue={(selected) => {
                                            return <Typography variant="h4"
                                                               sx={{fontWeight: 'bold'}}>{t("Attributes.vacationType")}</Typography>
                                        }}>
                                            {Object.entries(vacationType).map(([key, value]) => (
                                                <MenuItem value={value} key={key}>
                                                    {t("Vacation.vac" + value)} {type == vacationType.All ?
                                                    <Typography> &nbsp; &#40;{(value as number) != vacationType.All ? eventsAll.filter(obj => obj.Event.Id_Type == value).length : eventsAll.length}&#41;</Typography> : null}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell align='center' className='user_vac_info'>
                                    <IconButton className={filer.name == "date" ? 'filter active' : 'filter'}
                                                size='small' onClick={() => handleFilter("date")}>
                                        <FilterListIcon
                                            className={filer.name == "date" && filer.direction == "asc" ? "upside_down" : "upside_down_back"}/>
                                    </IconButton>
                                    <FormControl variant="standard">
                                        <Select onChange={(e) => setYear(e.target.value as number)}
                                                className={year != 2 ? "select_color" : "select"} value={year}
                                                disableUnderline renderValue={(selected) => {
                                            return <Typography variant="h4"
                                                               sx={{fontWeight: 'bold'}}>{t("Attributes.vacationDate")}</Typography>
                                        }}>
                                            <MenuItem value={2}>
                                                {t("UserPanel.status0")}
                                            </MenuItem>
                                            <MenuItem value={new Date().getFullYear() - 2}>
                                                {new Date().getFullYear() - 2}
                                            </MenuItem>
                                            <MenuItem value={new Date().getFullYear() - 1}>
                                                {new Date().getFullYear() - 1}
                                            </MenuItem>
                                            <MenuItem value={new Date().getFullYear()}>
                                                {new Date().getFullYear()}
                                            </MenuItem>
                                            <MenuItem value={new Date().getFullYear() + 1}>
                                                {new Date().getFullYear() + 1}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton className={filer.name == "days" ? 'filter_days active' : 'filter_days'}
                                                size='small' onClick={() => handleFilter("days")}>
                                        <FilterListIcon
                                            className={filer.name == "days" && filer.direction == "asc" ? "upside_down" : "upside_down_back"}/>
                                    </IconButton>
                                    <FormControl variant="standard">
                                        <Typography variant="h4" sx={{fontWeight: 'bold'}}>
                                            {t("Attributes.numberOfDays")}
                                        </Typography>
                                    </FormControl>
                                </TableCell>
                                <TableCell align='center'>            
                                    <Typography variant="h4" sx={{fontWeight: 'bold',marginTop:"-3px"}}>{t("Attributes.description")}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <FormControl variant="standard">
                                        <Select onChange={(e) => setStatus(e.target.value as number)} value={status}
                                                className={status != vacationStatus.All ? "select_color" : "select"}
                                                disableUnderline renderValue={(selected) => {
                                            return <Typography variant="h4"
                                                               sx={{fontWeight: 'bold'}}>Status</Typography>
                                        }}>
                                            {Object.entries(vacationStatus).map(([key, value]) => (
                                                <MenuItem value={value} key={key}>
                                                    {t("UserPanel.status" + value)} {status == vacationStatus.All ?
                                                    <Typography> &nbsp; &#40;{(value as number) != vacationStatus.All ? eventsAll.filter(obj => obj.Event.Id_Status == value).length : eventsAll.length}&#41;</Typography> : null}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell align='center'>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {events.map((row) => (
                                <TableRow sx={{ "& td": { border: 0 } }} className="row" key={row.Event.Id_Event}>
                                    <TableCell style={{ width: "10%" }}>
                                        <div className={'type_div' + row.Event.Id_Type}>
                                            <Typography variant='h5'>{t('Vacation.vac' + row.Event.Id_Type)}</Typography>
                                        </div>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Typography
                                            variant='h5'>{row.Event.StartDate.substring(0, row.Event.StartDate.indexOf('T'))} - {row.Event.EndDate.substring(0, row.Event.StartDate.indexOf('T'))}</Typography>
                                    </TableCell>
                                    <TableCell align='center'>
                                        {days(row, 'h5')}
                                    </TableCell>
                                    <TableCell align='center' sx={{minWidth:"300px", maxWidth: "720px"}}><Typography
                                        variant='h5'>{row.Event.Description}</Typography>
                                        {row.Event.Id_Status == vacationStatus.Rejected ?
                                            <Typography>{t("Attributes.reason")}: {row.Event.Reason}</Typography>
                                            : null}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Typography variant='h5' className={'status' + row.Event.Id_Status}>{t('UserPanel.status' + row.Event.Id_Status)}</Typography>
                                    </TableCell>
                                    <TableCell style={{ maxWidth: '90px' }}>
                                            <Button className={[vacationStatus.Rejected, vacationStatus.Resign, vacationStatus.Completed].includes(row.Event.Id_Status)? "button grayscale" : "button"} onClick={(e) => setAnchorEl({ anchor: e.currentTarget as any, row: row })} disabled={[vacationStatus.Rejected, vacationStatus.Resign, vacationStatus.Completed].includes(row.Event.Id_Status)}>
                                                <img src={Gear} />
                                            </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Pagination className='verical_center'
                                                count={Math.ceil(eventsAll.length / PageSize)} page={page}
                                                onChange={(e, value) => {
                                                    setPage(value)
                                                }}/>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
            <Menu
                anchorEl={anchorEl.anchor}
                open={Menuopen}
                onClose={()=>setAnchorEl(anchorEl=>({...anchorEl, anchor: null }))}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => setOpen({ open: true, action: "extend", row: anchorEl.row })} disabled={anchorEl.row?.Event.Id_Type==vacationType.Extended}>{t('UserPanel.extend')}</MenuItem>
                {anchorEl.row?.Event.Id_Status == vacationStatus.Waiting ?
                    <MenuItem onClick={() => deleteEvent(anchorEl.row?.Event.Id_Event)}>{t('UserPanel.delete')}</MenuItem>
                    :
                    <MenuItem onClick={() => resignEvent(anchorEl.row?.Event.Id_Event)}>{t('UserPanel.resign')}</MenuItem>
                }
            </Menu>
        </div>
    )
};

export default UserPanel