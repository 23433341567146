import React, { useState, useMemo, useEffect } from "react";
// @ts-ignore
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import { Typography, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import 'moment/locale/pl';
import { eventRes, events } from '../components/Models';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment)

const messages = {
    allDay: 'Cały dzień',
    previous: '<',
    next: '>',
    today: 'Dziś',
    month: 'Miesiąc',
    week: 'Tydzień',
    day: 'Dzień',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Czas',
    event: 'Wydarzenia',
    showMore: (total:number) => `+ ${total} Więcej`,
};

const messagesAng = {
    previous: '<',
    next: '>'
};

const eventStyleGetter = (event: events) => {
    if (event.id_Type == 1) {
        var backgroundColor = window.getComputedStyle(document.body).getPropertyValue('--vac1');
    } else if (event.id_Type == 2) {
        var backgroundColor = window.getComputedStyle(document.body).getPropertyValue('--vac2');
    } else {
        var backgroundColor = window.getComputedStyle(document.body).getPropertyValue('--vac3');
    }
    var style = {
        backgroundColor: backgroundColor,
        height: "30px",
        margin: "1px",
        borderRadius: '10px',
        opacity: 0.6,
        color: 'black',
        display: 'block',
        fontWeight: 'bold'
    };
    return {
        style: style
    };
}

const Calendar = (language: any) => {
    const [userevents, setUserEvents] = useState<eventRes[]>([]);
    const [viewCalendar, setView] = useState("month");
    const [anchorEl, setAnchorEl] = useState(null);
    const [selected, setSelected] = useState("");
    const { t } = useTranslation("");

    useEffect(() => {
        const fetchEvents = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL+"/api/event/search?status_id=2", {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                credentials: "include"
            });
            setUserEvents(await response.json());
        };
        fetchEvents();
    }, []);

    const events = userevents.map((event) => {
        return {
            id: event.Event.Id_Event,
            title: event.DisplayName,
            start: event.Event.StartDate,
            end: moment(event.Event.EndDate, "YYYY-MM-DD").add('days', 1),
            allDay: true,
            description: event.Event.Description.length > 0 ? event.Event.Description : "a",
            id_Type: event.Event.Id_Type,
        }
    })

    const handleSelected = (event: events) => {
        setSelected(JSON.parse(JSON.stringify(event.description)));
    };

    const handleClose = () => {
        setSelected("");
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <div className="div_calendar">
                {selected.length > 0 ?
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        PaperProps={{
                            style: {
                                backgroundColor: "transparent",
                            }
                        }}
                    >
                        <div className="tooltip_calendar">
                            <Typography variant='h5' sx={{ fontWeight: "bold" }}>{t("Calendar.description")}</Typography>
                            <Typography variant='h5'>{selected.length > 1 ? selected : t("Calendar.noDescription")}</Typography>
                        </div>
                    </Popover>
                    : null}
                <BigCalendar
                    localizer={localizer}
                    events={events}
                    views={['month', 'week']}
                    startAccessor="start"
                    view={viewCalendar}
                    onView={setView}
                    messages={language.language == "pl" ? messages : messagesAng}
                    culture={language.language == "pl" ? undefined : "en-GB"}
                    endAccessor="end"
                    defaultDate={moment().toDate()}
                    eventPropGetter={eventStyleGetter}
                    onSelectEvent={handleSelected}
                    elementProps={{ onMouseOver: (e: any) => setAnchorEl(e.target) }}
                    onShowMore={(events: any, date: any) => setView("week")}
                />
            </div>
        </>
    )
}
export default Calendar