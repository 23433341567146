import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Drawer, Box, List, ListItemButton, ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useView } from '../context/ViewContext';
import Calendar from "../assets/calendar";
import Logo from '../assets/Logo.svg'
import Panel from '../assets/panel';

function Navbar() {
    const { width, view } = useView();
    const [open, setOpen] = useState<boolean>(false);

    const itemsList = () => (
        <Box className="nav_mobile">
            <List>
                <ListItemButton disableRipple>
                    <ListItemIcon >
                        <img src={Logo} alt='Logo' className="img_small" />
                    </ListItemIcon>
                </ListItemButton>
                <div className="div_icon">
                    <ListItemIcon >
                        <div className="circle_nav">
                            <NavLink to="/">
                                <Panel />
                            </NavLink>
                        </div>
                    </ListItemIcon>
                    <ListItemIcon >
                        <div className="circle_nav">
                            <NavLink to="/Calendar" >
                                <Calendar />
                            </NavLink>
                        </div>
                    </ListItemIcon>
                </div>
            </List>
        </Box >
    );

    return (
        <>
            {view == "desktop" ?
                <div className="nav">
                    <div className="circle_nav_logo">
                        <img src={Logo} alt='Logo' className="img_small" />
                    </div>
                    <div className="div_icon">
                        <div>
                            <NavLink to="/">
                                <div className="circle_nav">
                                <Panel />
                                </div>
                            </NavLink>
                        </div>
                        <div>
                            <NavLink to="/Calendar">
                                <div className="circle_nav">
                                <Calendar />
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
                :
                <>
                    <Button onClick={() => setOpen(true)} ><MenuIcon sx={{ fontSize: 45, color: "black" }} /></Button>
                    <Drawer
                        anchor={"left"}
                        open={open}
                        onClose={() => setOpen(false)}
                    >
                        {itemsList()}
                    </Drawer>
                </>}
        </>
    )
}

export default Navbar