import React from 'react';

const Calendar = (fill?:any) => (
<svg width="50" height="50" viewBox="0 0 32 32" fill="white" xmlns="http://www.w3.org/2000/svg">
<path d="M5.09585 2.42527C5.12636 1.81128 5.15305 1.25067 5.17974 0.632861C5.93478 0.632861 6.65168 0.632861 7.4868 0.632861C7.4868 1.19346 7.4868 1.77316 7.4868 2.41766C9.1151 2.41766 10.6252 2.41766 12.2535 2.41766C12.2535 1.84562 12.2535 1.26594 12.2535 0.629067C13.0886 0.629067 13.8055 0.629067 14.6177 0.629067C14.6521 1.20492 14.6902 1.7884 14.7283 2.42527C16.2842 2.42527 17.7943 2.42527 19.3844 2.42527C19.4225 1.80746 19.4569 1.22017 19.4912 0.617615C20.2729 0.617615 20.9936 0.617615 21.7982 0.617615C21.7982 1.21635 21.7982 1.77315 21.7982 2.40621C22.1453 2.43672 22.4198 2.46723 22.6906 2.4863C24.4294 2.60071 25.9319 3.84775 26.0387 5.57152C26.1721 7.7491 26.073 9.94195 26.073 12.1882C25.3027 12.1882 24.5858 12.1882 23.7507 12.1882C23.7507 11.3797 23.7507 10.5712 23.7507 9.70168C16.8867 9.70168 10.1371 9.70168 3.31121 9.70168C3.31121 13.6107 3.31121 17.5044 3.31121 21.5239C6.26273 21.5239 9.22568 21.5239 12.2382 21.5239C12.2382 22.3744 12.2382 23.0913 12.2382 23.8693C8.45539 23.8693 4.68782 23.8693 0.851607 23.8693C0.851607 23.3469 0.851607 22.8778 0.851607 22.4049C0.851607 17.2565 0.939311 12.1081 0.813471 6.9635C0.756271 4.48845 1.95366 2.45197 5.09585 2.42527ZM23.6706 7.19994C23.846 5.19016 23.5448 4.86219 21.6267 4.86219C16.1393 4.86219 10.6481 4.86219 5.16067 4.86219C3.43704 4.86219 2.97564 5.45711 3.37985 7.19994C10.1142 7.19994 16.86 7.19994 23.6706 7.19994Z" fill="white"/>
<path d="M22.969 31.077C18.4121 31.0618 14.6788 27.3054 14.6826 22.7366C14.6864 18.1145 18.4502 14.3734 23.0643 14.4039C27.6785 14.4344 31.385 18.2022 31.3545 22.8358C31.324 27.3778 27.545 31.0923 22.969 31.077ZM17.0621 22.6451C17.024 25.9287 19.6323 28.6325 22.8965 28.6897C26.2065 28.7507 28.9445 26.085 28.9636 22.7824C28.9826 19.5027 26.3438 16.8103 23.0834 16.7836C19.8039 16.7569 17.1003 19.3921 17.0621 22.6451Z" fill="white"/>
<path d="M5.21777 12.0433C5.99951 12.0433 6.6859 12.0433 7.44856 12.0433C7.44856 12.806 7.44856 13.542 7.44856 14.3391C6.72403 14.3391 6.01095 14.3391 5.21777 14.3391C5.21777 13.5916 5.21777 12.8594 5.21777 12.0433Z" fill="white"/>
<path d="M9.99609 12.0433C10.7397 12.0433 11.4299 12.0433 12.2078 12.0433C12.2078 12.7869 12.2078 13.523 12.2078 14.3276C11.5138 14.3276 10.8007 14.3276 9.99609 14.3276C9.99609 13.6145 9.99609 12.8823 9.99609 12.0433Z" fill="white"/>
<path d="M17.0281 12.0966C17.0281 12.8708 17.0281 13.5611 17.0281 14.3238C16.2616 14.3238 15.5294 14.3238 14.7324 14.3238C14.7324 13.5763 14.7324 12.867 14.7324 12.0966C15.5142 12.0966 16.2654 12.0966 17.0281 12.0966Z" fill="white"/>
<path d="M7.47196 19.1061C6.67498 19.1061 5.9657 19.1061 5.19922 19.1061C5.19922 18.3395 5.19922 17.6302 5.19922 16.8484C5.95807 16.8484 6.68642 16.8484 7.47196 16.8484C7.47196 17.5959 7.47196 18.309 7.47196 19.1061Z" fill="white"/>
<path d="M9.91167 19.0832C9.91167 18.4349 9.90404 17.8667 9.91548 17.3023C9.91929 17.1497 9.96887 17.001 10.0032 16.8256C10.743 16.8256 11.4561 16.8256 12.2302 16.8256C12.2302 17.5807 12.2302 18.2938 12.2302 19.0832C11.5018 19.0832 10.7697 19.0832 9.91167 19.0832Z" fill="white"/>
<path d="M24.2235 19.251C24.2235 20.0328 24.2235 20.7268 24.2235 21.5125C25.0052 21.5125 25.7221 21.5125 26.5153 21.5125C26.5153 22.3438 26.5153 23.0799 26.5153 23.8731C24.9824 23.8731 23.4723 23.8731 21.8936 23.8731C21.8936 22.3362 21.8936 20.8298 21.8936 19.251C22.641 19.251 23.3998 19.251 24.2235 19.251Z" fill="white"/>
</svg>
);

export default Calendar;