import React, { createContext, useEffect, useState, useContext } from "react";

export const viewContext = createContext({});

const mobile = 450;
const tablet = 800;

export const ViewProvider = ({ children }: any) => {
  
  const handleWindowSize = () => {
    if (window.innerWidth <= mobile) {
      return "mobile";
    }else if(window.innerWidth >tablet){
      return "desktop";
    }else{
      return "tablet";
    }
  }

  const [width, setWidth] = useState(window.innerWidth);
  const [view, setView] = useState<string>(handleWindowSize());

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth <= mobile) {
      setView("mobile");
    }else if(window.innerWidth >tablet){
      setView("desktop")
    }else{
      setView("tablet")
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewContext.Provider value={{ width,view }}>
      {children}
    </viewContext.Provider>
  );
};

export const useView = () => {
  const { width, view }: any = useContext(viewContext);
  return { width, view };
}