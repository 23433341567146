import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { CircularProgress, Box, Typography } from '@mui/material';
import { daysCirc } from "../../components/Methods"

const Circle = ({ vac, value, infinite = false }: { vac: number, value: number, infinite?: boolean }) => {
    const { t } = useTranslation();

    return (
        <Box className='circle_box' >
            <CircularProgress
                variant="determinate"
                thickness={5}
                value={100}
                className={"vac_circ" + vac + "_bg"}
            />
            <CircularProgress
                variant="determinate"
                value={infinite ? (1 - (0.05 * value)) * 100 : (value) * 100}
                className={"vac_circ" + vac}
                thickness={5}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                className="circ2"
            >
                {infinite ?
                    daysCirc(value, 'h6')
                    :
                    <Typography variant="h6">{`${Math.round((value) * 100)}%`}</Typography>
                }
            </Box>
        </Box>
    )
}

export default Circle;