import React, { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { Typography, Select, MenuItem, FormControl, InputLabel, Autocomplete, TextField, Pagination } from "@mui/material";
import { ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid, Bar, BarChart, Legend } from "recharts";
import { useTranslation, Trans } from 'react-i18next';
import { vacationTypeMain } from '../../components/Models';
import { useView } from '../../context/ViewContext';
import { userRes } from "../../components/Models";

const Statistics = () => {
    const { t } = useTranslation("");
    const [users, setUsers] = useState<userRes[]>([]);
    const [usersAll, setUsersAll] = useState<userRes[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<userRes[]>([]);
    const [searchType, setSearchType] = useState<string|number>(0);
    const [pageSize, setPageSize] = useState<number>(20);
    const [page, setPage] = useState<number>(1);
    const [max, setMax] = useState<number>(0);
    const vac1 = window.getComputedStyle(document.body).getPropertyValue('--vac1-rgb');
    const vac2 = window.getComputedStyle(document.body).getPropertyValue('--vac2-rgb');
    const vac3 = window.getComputedStyle(document.body).getPropertyValue('--vac3-rgb');
    const { width, view } = useView();
    const ele = document.getElementsByClassName("recharts-yAxis")[0];
    const recordHeight = view == "mobile" ? 60 : 70;
    const theme = useTheme();
    const stacked = false;

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL+"/api/user/users_short", {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                credentials: "include"
            });
            const res = await response.json();
            let resSorted = res.sort((a: userRes, b: userRes) => (b.UsedPaidDays + b.UsedOnDemandDays + b.UsedUnpaidDays) - (a.UsedPaidDays + a.UsedOnDemandDays + a.UsedUnpaidDays));
            setUsersAll(resSorted);
            const lastId = page * pageSize;
            const firstId = lastId - pageSize;
            setUsers(resSorted.slice(firstId, lastId));
        };
        fetchUsers();
    }, [, searchType, page, pageSize]);

    useEffect(() => {
        let currentUsers = usersAll;
        if (selectedUsers.length > 0) {
            currentUsers = selectedUsers;
        }
        if (stacked) {
            setMax(Math.max(...currentUsers.map(o => o.UsedUnpaidDays + o.UsedPaidDays + o.UsedOnDemandDays)));
        }
        else {
            const unpaid = Math.max(...currentUsers.map(o => o.UsedUnpaidDays));
            const paid = Math.max(...currentUsers.map(o => o.UsedPaidDays));
            const onDemand = Math.max(...currentUsers.map(o => o.UsedOnDemandDays));
            setMax(Math.max(unpaid, paid, onDemand));
        }
    }, [usersAll, selectedUsers]);

    useEffect(() => {
        setPage(1);
    }, [searchType, pageSize]);


    const onScroll = (e: any) => {
        // @ts-ignore 
        ele.style = "transform: translateX(" + e.target.scrollLeft + "px);";
    }

    useEffect(() => {
        if (view == "desktop") {
            if (ele != null) {
                // @ts-ignore 
                ele.style = "transform: translateX(" + 0 + "px);";
            }
        }
    }, [view])


    return (
        <>
            <div className='div_stats' onScroll={(e) => { onScroll(e) }}>
                <Typography variant="h3" className="title">{t("Statistics.StatisticsHeader1")}</Typography>
                <div className='stat_options'>
                    <FormControl variant="standard" sx={{ minWidth: 120, marginTop: "-3px" }} >
                        <InputLabel><Typography variant="h5">{t("Statistics.type")}</Typography></InputLabel>
                        <Select onChange={(e) => setSearchType(e.target.value)} value={searchType}>
                            {Object.entries(vacationTypeMain).map(([key, value]) => (
                                <MenuItem value={value} key={key}>
                                    {t("Vacation.vac" + value)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Autocomplete
                        multiple
                        size="small"
                        limitTags={pageSize}
                        options={usersAll}
                        disableCloseOnSelect
                        onChange={(e, val) => setSelectedUsers(val)}
                        getOptionLabel={(option) => option.DisplayName}
                        style={{ width: 600 }}
                        renderInput={(params) => (
                            <TextField {...params} variant="standard" label={<Typography variant="h5">{t("Statistics.users")}</Typography>} />
                        )}
                    />
                    <FormControl variant="standard" sx={{ minWidth: 120, marginTop: "-3px" }}>
                    <InputLabel><Typography variant="h5">{t("Statistics.pageSize")}</Typography></InputLabel>
                        <Select onChange={(e) => setPageSize(e.target.value as number)} value={pageSize}>
                            <MenuItem value={20}>{20}</MenuItem>
                            <MenuItem value={30}>{30}</MenuItem>
                            <MenuItem value={50}>{50}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <ResponsiveContainer width={width >= 800 ? "100%" : "200%"} height={selectedUsers.length > 0 ? 100 + (selectedUsers.length * recordHeight) : 100 + (users.length * recordHeight)}>
                    <BarChart
                        data={selectedUsers.length > 0 ? selectedUsers : users}
                        layout="vertical"
                        margin={{ top: 20, right: 15, left: recordHeight - 20, bottom: 5 }}
                        barCategoryGap={10} barGap={1}
                    >
                        <CartesianGrid stroke="#d2d2d2" horizontal={false} strokeDasharray="5 5" />
                        <Tooltip content={<CustomTooltip />} wrapperStyle={{ outline: "none" }} cursor={{ fill: theme.palette.mode == "light" ? 'rgba(240,240,240,0.6)' : 'rgba(60,60,60,0.6)' }} />
                        <Legend layout="horizontal" verticalAlign="top" align="center" iconSize={20} iconType="diamond" />
                        <Bar hide={searchType != "" && searchType != "1" ? true : false} name={t("Statistics.vacationType1")} dataKey="UsedUnpaidDays" fill={"rgba(" + vac1 + ",0.7"} background={{ fill: theme.palette.mode == "light" ? 'rgba(240,240,240,0.4)' : 'rgba(60,60,60,0.4)' }} stroke={"rgb(" + vac1 + ")"} strokeWidth={2} stackId={stacked ? "stack" : "1"} />
                        <Bar hide={searchType != "" && searchType != "2" ? true : false} name={t("Statistics.vacationType2")} dataKey="UsedPaidDays" fill={"rgba(" + vac2 + ",0.7"} background={{ fill: theme.palette.mode == "light" ? 'rgba(240,240,240,0.4)' : 'rgba(60,60,60,0.4)' }} stroke={"rgb(" + vac2 + ")"} strokeWidth={2} stackId={stacked ? "stack" : "2"} />
                        <Bar hide={searchType != "" && searchType != "3" ? true : false} name={t("Statistics.vacationType3")} dataKey="UsedOnDemandDays" fill={"rgba(" + vac3 + ",0.7"} background={{ fill: theme.palette.mode == "light" ? 'rgba(240,240,240,0.4)' : 'rgba(60,60,60,0.4)' }} stroke={"rgb(" + vac3 + ")"} strokeWidth={2} stackId={stacked ? "stack" : "3"} />
                        <XAxis type="number" dataKey="UsedPaidDays" allowDecimals={false} domain={[0, max]} interval={0} tickCount={max + 1} tick={{ fill: theme.palette.mode == "light" ? "rgb(27,27,27)" : "rgb(225,225,225)" }} />
                        <YAxis type="category" dataKey="DisplayName" tickMargin={2} tick={{ fill: theme.palette.mode == "light" ? "rgb(27,27,27)" : "rgb(225,225,225)" }} />
                    </BarChart>
                </ResponsiveContainer>
                <Pagination className='verical_center pagination' count={Math.ceil(usersAll.length / pageSize)} page={page} onChange={(e, value) => { setPage(value) }} />
            </div>
        </>
    );
};

function CustomTooltip({ active, payload, label }: any) {
    if (active && payload) {
        return (
            <div className="tooltip">
                <Typography variant="h4" sx={{fontWeight:"bold"}}>{label}</Typography>
                {payload.map((pld: any) => (
                    <div style={{ display: "inline-block", padding: 10 }}>
                        <div style={{ color: pld.fill, fontWeight: "bold" }} className='number_font'>{pld.value}</div>
                        <Typography variant="h5">{pld.name}</Typography>
                    </div>
                ))}
            </div>
        );
    }
    return null;
}

export default Statistics;