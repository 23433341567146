import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router} from 'react-router-dom';
import { UserProvider } from "./context/UserContext";
import { ViewProvider} from "./context/ViewContext";
import { SnacbarProvider } from './context/SnackbarContext';
import App from './App';
import './index.scss';

let root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <UserProvider>
        <ViewProvider>
            <SnacbarProvider>
            <Router>
            <App />
            </Router>
            </SnacbarProvider>
        </ViewProvider>
    </UserProvider>
);

