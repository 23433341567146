import React, { useState, useEffect, useContext } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableFooter, Pagination, TableRow, TableHead, Button } from '@mui/material';
import { Typography, Paper, TextField, InputAdornment, Select, MenuItem, FormControl, IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { calcBusinessDaysConv, days } from '../../components/Methods';
import { SnackbarContext } from '../../context/SnackbarContext';
import AvatarGenerator from '../../components/AvatarGenerator';
import {eventRes, loginForm} from '../../components/Models';
import LoginOptions from '../../components/LoginOptions';
import {vacationType} from '../../components/Models';
import UserSetings from "../../assets/userSetings.svg";
import {useView} from '../../context/ViewContext';
import StatusDialog from './StatusDialog';
import Pencil from "../../assets/pencil";
import Add from "../../assets/add.svg";
import UsersList from './UsersList';

const AdminPanel = () => {
    const [events, setEvents] = useState<eventRes[]>([]);
    const [page, setPage] = useState<number>(1);
    const [chosenRow, setChosenRow] = useState<eventRes | null>(null);
    const [eventsAll, setEventsAll] = useState<eventRes[]>([]);
    const [openList, setOpenList] = useState<boolean>(false);
    const [openLogin, setOpenLogin] = useState<boolean>(false);
    const [searchName, setSearchName] = useState<string>("");
    const [type, setType] = useState<number>(vacationType.All);
    const [year, setYear] = useState(new Date().getFullYear());
    const [filer, setFilter] = useState({name: "date", direction: "desc"});
    const { setSnack } = useContext(SnackbarContext);
    const {width, view} = useView();
    const {t} = useTranslation();
    const PageSize = 10;

    useEffect(() => {
        const fetchEvents = async () => {
            var typeEndpoint = "";
            if (type != vacationType.All) {
                typeEndpoint = "&type_id=" + type;
            }
            const response = await fetch(process.env.REACT_APP_API_URL+"/api/event/search?status_id=1" + typeEndpoint, {
                method: "GET",
                headers: {
                    // Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: "include"
            });
            let res = await response.json();
            if (searchName != "") {
                res = res.filter((user: eventRes) => user.DisplayName.toLowerCase().includes(searchName.toLowerCase()))
            }
            const resYear = res.filter((event: eventRes) => event.Event.StartDate.includes(String(year)));
            setEventsAll(resYear);
            let resSort = resYear;
            if (filer.name == "date") {
                if (filer.direction == "asc") {
                    resSort = resYear.sort((a: eventRes, b: eventRes) => new Date(a.Event.StartDate).valueOf() - new Date(b.Event.StartDate).valueOf());
                } else {
                    resSort = resYear.sort((a: eventRes, b: eventRes) => new Date(b.Event.StartDate).valueOf() - new Date(a.Event.StartDate).valueOf());
                }
            } else if (filer.name == "days") {
                if (filer.direction == "asc") {
                    resSort = resYear.sort((a: eventRes, b: eventRes) => calcBusinessDaysConv(a.Event.StartDate, a.Event.EndDate) - calcBusinessDaysConv(b.Event.StartDate, b.Event.EndDate));
                } else {
                    resSort = resYear.sort((a: eventRes, b: eventRes) => calcBusinessDaysConv(b.Event.StartDate, b.Event.EndDate) - calcBusinessDaysConv(a.Event.StartDate, a.Event.EndDate));
                }
            }
            const lastId = page * PageSize;
            const firstId = lastId - PageSize;
            setEvents(resSort.slice(firstId, lastId));
        };
        fetchEvents();
    }, [, chosenRow, searchName, type, year, page, filer]);

    useEffect(() => {
        setPage(1);
    }, [searchName, type, year]);

    const addLogin = async (data: loginForm, id: number) => {
        const response = await fetch(process.env.REACT_APP_API_URL+"/api/login/", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            credentials: "include",
            body: JSON.stringify(
                {
                    "Email": data.email,
                    "Role": "USER",
                    "Password": data.password,
                    "Id_User": data.user
                }
            ),
        });
        const res = await response.json();
        if(res!="loginAlreadyExists"){
        setSnack({ message: t("Snackbar.success"), type: "success", open: true });
        }else{
        setSnack({ message: t("Login." + res), type: "error", open: true });
        }
        return res;
    };

    const handleFilter = (Name: string) => {
        let Direction = "";
        if ((filer.name != Name) || (filer.name == Name && filer.direction == "asc")) {
            Direction = "desc";
        } else {
            Direction = "asc";
        }
        setFilter({name: Name, direction: Direction});
    }

    const handleClose = () => setChosenRow(null);

    return (
        <>
            {
                openList == true ? <UsersList onClose={() => setOpenList(false)}/> : null
            }
            {
                openLogin == true ?
                    <LoginOptions onClose={() => setOpenLogin(false)} title={t("AdminPanel.addLoginTitle")}
                                  Submit={addLogin}/> : null
            }
            <div>
                {chosenRow != null ? <StatusDialog onClose={handleClose} row={chosenRow}/> : null}
                <Paper elevation={4} className="admin_paper_button">
                    <Button variant="contained" onClick={() => setOpenList(true)} className="admin_button"><img src={UserSetings} /><Typography variant="h5">{t("AdminPanel.assignVacation")}</Typography></Button>
                    <Button variant="contained" onClick={() => setOpenLogin(true)} className="admin_button"><img src={Add} /><Typography variant="h5" >{t("AdminPanel.addLogin")}</Typography></Button>
                </Paper>
                <Paper elevation={4} className="table_paper_panel center">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>
                                        <TextField
                                            placeholder={t("Attributes.user")}
                                            className={searchName.length > 0 ? "search active" : "search"}
                                            variant="standard"
                                            onChange={(e) => setSearchName(e.target.value)}
                                            size="small"
                                            InputProps={{
                                                startAdornment: <InputAdornment
                                                    position="start"><SearchIcon/></InputAdornment>
                                            }}
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <FormControl variant="standard" >
                                            <Select onChange={(e) => setType(e.target.value as number)} value={type} className={type != vacationType.All ? "select_color" : "select"} disableUnderline renderValue={(selected) => { return <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{t("Attributes.vacationType")}</Typography> }}>
                                                {Object.entries(vacationType).map(([key, value]) => (                              
                                                    <MenuItem value={value} key={key}>
                                                        {t("Vacation.vac" + value)} {type == vacationType.All ?
                                                        <Typography> &nbsp; &#40;{(value as number) != vacationType.All ? eventsAll.filter(obj => obj.Event.Id_Type == value).length : eventsAll.length}&#41;</Typography> : null}
                                                    </MenuItem>                                                 
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align='center' className='user_vac_info'>
                                        <IconButton className={filer.name == "date" ? 'filter active' : 'filter'}
                                                    size='small' onClick={() => handleFilter("date")}>
                                            <FilterListIcon
                                                className={filer.name == "date" && filer.direction == "asc" ? "upside_down" : "upside_down_back"}/>
                                        </IconButton>
                                        <FormControl variant="standard" >
                                            <Select onChange={(e) => setYear(e.target.value as number)} className={year != 2 ? "select_color" : "select"} value={year} disableUnderline renderValue={(selected) => { return <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{t("Attributes.vacationDate")}</Typography> }}>
                                                <MenuItem value={2}>
                                                    {t("UserPanel.status0")}
                                                </MenuItem>
                                                <MenuItem value={new Date().getFullYear() - 2}>
                                                    {new Date().getFullYear() - 2}
                                                </MenuItem>
                                                <MenuItem value={new Date().getFullYear() - 1}>
                                                    {new Date().getFullYear() - 1}
                                                </MenuItem>
                                                <MenuItem value={new Date().getFullYear()}>
                                                    {new Date().getFullYear()}
                                                </MenuItem>
                                                <MenuItem value={new Date().getFullYear() + 1}>
                                                    {new Date().getFullYear() + 1}
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <IconButton
                                            className={filer.name == "days" ? 'filter_days active' : 'filter_days'}
                                            size='small' onClick={() => handleFilter("days")}>
                                            <FilterListIcon
                                                className={filer.name == "days" && filer.direction == "asc" ? "upside_down" : "upside_down_back"}/>
                                        </IconButton>
                                        <FormControl variant="standard">
                                            <Typography variant="h4" sx={{fontWeight: 'bold'}}>
                                                {t("Attributes.numberOfDays")}
                                            </Typography>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Typography variant="h4"
                                                    sx={{fontWeight: 'bold',marginTop:"-3px"}}>{t("Attributes.description")}</Typography>
                                    </TableCell>
                                    <TableCell align='center'>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {events.map((row) => (
                                    <TableRow sx={{ "& td": { border: 0 } }} className="row" key={row.Event.Id_Event}>
                                        <TableCell align='right' sx={{ maxWidth: '200px' }}>
                                            <div className='table_user'>
                                                <AvatarGenerator name={row.DisplayName} /><Typography variant='h5'>{row.DisplayName}</Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: "10%" }}>
                                            <div className={'type_div' + row.Event.Id_Type}>
                                                <Typography variant='h5'>{t('Vacation.vac' + row.Event.Id_Type)}</Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Typography
                                                variant='h5'>{row.Event.StartDate.substring(0, row.Event.StartDate.indexOf('T'))} - {row.Event.EndDate.substring(0, row.Event.StartDate.indexOf('T'))}</Typography>
                                        </TableCell>
                                        <TableCell align='center'>
                                            {days(row, 'h5')}
                                        </TableCell>
                                        <TableCell align='center' sx={{minWidth:"300px", maxWidth: "720px"}}><Typography
                                            variant='h5'>{row.Event.Description}</Typography></TableCell>
                                        <TableCell>
                                            <IconButton className="pencil" size='small'
                                                        onClick={() => setChosenRow(row)}>
                                                <Pencil/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Pagination className='verical_center'
                                                    count={Math.ceil(eventsAll.length / PageSize)} page={page}
                                                    onChange={(e, value) => {
                                                        setPage(value)
                                                    }}/>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        </>
    )
}

export default AdminPanel