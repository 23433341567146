import React, { useState, useContext, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { PaletteMode } from '@mui/material';
import { initReactI18next, useTranslation, Trans } from "react-i18next";
import i18n from "i18next";
import { currentUserContext } from './components/Models';
import AdminPanel from "./pages/adminPanel/AdminPanel";
import Statistics from "./pages/statistics/Statistics";
import UserPanel from "./pages/userPanel/UserPanel";
import { UserContext } from "./context/UserContext";
import UserOptions from "./components/UserOptions";
import { useView } from './context/ViewContext';
import NotFound from './components/NotFound';
import Navbar from './components/Navbar';
import Calendar from "./pages/Calendar";
import Login from "./pages/login/Login";
import pl from "./localization/pl.json";
import en from "./localization/en.json";
import Logo from './assets/Logo.svg';

// const UserPanel = React.lazy(() => import("./pages/userPanel/UserPanel"));
// const Calendar = React.lazy(() => import("./pages/Calendar"));
// const AdminPanel = React.lazy(() => import("./pages/adminPanel/AdminPanel"));
// const Login = React.lazy(() => import("./pages/login/Login"));
// const Statistics = React.lazy(() => import("./pages/statistics/Statistics"));
// const Navbar = React.lazy(() => import("./components/Navbar"));
// const UserOptions = React.lazy(() => import("./components/UserOptions"));

// @ts-ignore
i18n
    .use(initReactI18next)
    .init({
        resources: {
            pl: { translation: pl },
            en: { translation: en }
        },
        returnNull: false,
        lng: localStorage.getItem("language") != null ? localStorage.getItem("language") : "pl",
        fallbackLng: "pl",
        interpolation: { escapeValue: false }
    })

const App = () => {
    const { user, setUser, message, setMessage } = useContext(UserContext) as currentUserContext;
    //const [theme, setTheme] = useState<string>(localStorage.getItem("theme") != null ? localStorage.getItem("theme") as string : "light");
    const [theme, setTheme] = useState<string>("light");
    const { t, i18n } = useTranslation();
    const { width, view } = useView();
    const location = useLocation();

    const themePalette = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: theme as PaletteMode,
                    background: {
                        default: theme == "dark" ? "#121212" : "#F7F7F7",
                    },
                },
                typography: {
                    allVariants: {
                        fontFamily: "'Lato', sans-serif "
                    }
                }
            }),
        [theme]
    );

    useEffect(() => {
        document.documentElement.setAttribute("data-theme", theme);
    }, [])

    const handleLanguage = (event: any) => {
        i18n.changeLanguage(event.target.value);
    };

    const handlePaths = () => {
        let Paths = ["/"];
        if (user != null) {
            Paths = user!.Login.Role == "ADMIN" ? ["/", "/Calendar", "/AdminPanel", "/Statistics"] : ["/", "/Calendar"];
        }
        return Paths.includes(location.pathname);
    }

    return (
        <React.StrictMode >
            <ThemeProvider theme={themePalette} >
                <CssBaseline />
                {user != null && handlePaths() ?
                    <div className={view == "desktop" ? 'app' : 'app scroll'}>
                        <div className="div_nav" >
                            <Navbar />
                        </div>
                        <div className={view == "desktop" ? 'div_page scroll' : 'div_page'}>
                            <UserOptions handleLanguage={handleLanguage} language={i18n.resolvedLanguage} theme={theme} setTheme={setTheme} />
                            <Routes>
                                <Route path="/" element={<UserPanel language={i18n.resolvedLanguage} />} />
                                <Route path="/Calendar" element={<Calendar language={i18n.resolvedLanguage} />} />
                                {user!.Login.Role == "ADMIN" ?
                                    <>
                                        <Route path="/AdminPanel" element={<AdminPanel />} />
                                        <Route path="/Statistics" element={<Statistics />} />
                                    </>
                                    : null}
                            </Routes>
                        </div>
                    </div>
                    :
                    <Routes>
                        <Route path="/" element={<Login handleLanguage={handleLanguage} language={i18n.resolvedLanguage} />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                }
            </ThemeProvider>
        </React.StrictMode>
    );
}

export default App;