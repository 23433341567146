import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material';
import { avatarGenerator } from './Models';

const AvatarGenerator = ({name, size='small'}:avatarGenerator) => {
  const [range, setRange] = useState(5);
  const [saturation, setSaturation] = useState(60);
  const [lightness, setLightness] = useState(60);

  const getHashOfString = (str:string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
  };

  const normalizeHash = (hash:number, min:number, max:number) => {
    return Math.floor((hash % (max - min)) + min);
  };

  const generateHSL = (name:any, saturationRange:any, lightnessRange:any) => {
    const hash = getHashOfString(name);
    let h = normalizeHash(hash, 0, 360);
    // filtering out ugly colors
    if(h>=48 && h<=57){
      h-=15;
    }
    if(h>=58 && h<=67){
      h+=15;
    }
    const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
    const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
    return [h, s, l];
  };

  const HSLtoString = (hsl: any) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
  };

  const generateColorHsl = (id:any, saturationRange:any, lightnessRange:any) => {
    return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
  };

  const getInitials =(string:string)=>{
    var names = string.toString().split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const getRange = (value:number, range:number) => {
    return [Math.max(0, value - range), Math.min(value + range, 100)];
  }

  const saturationRange = getRange(saturation, range);
  const lightnessRange = getRange(lightness, range);
  const color = generateColorHsl(name, saturationRange, lightnessRange);
  const initials = getInitials(name);

  return (
    <>
      <div className={size=='small'? 'avatarGenerator':'avatarGenerator big'} style={{ backgroundColor: color }}><Typography>{initials}</Typography></div>
    </>
  )
}

export default AvatarGenerator;