import React, { useState, useContext } from "react";
import { Dialog as MuiDialog, DialogContent, DialogContentText, Typography, Paper, DialogTitle, Button, TextField} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from "yup";
import 'dayjs/locale/pl';
import { SnackbarContext } from '../../context/SnackbarContext';
import AvatarGenerator from "../../components/AvatarGenerator";
import { statusDialog } from '../../components/Models';
import { days } from '../../components/Methods';

const Dialog = ({ onClose, row }: statusDialog) => {
    const { setSnack } = useContext(SnackbarContext);
    const [status, setStatus] = useState<number>(0);
    const { t } = useTranslation();

    const shema = yup.object().shape({
        reason: yup.string()
            .required(t("Form.required"))
            .max(255,t("Form.toLong"))
    });

    const formik = useFormik({
        initialValues: {
            reason: ''
        },
        validationSchema: shema,
        onSubmit: (values) => {
            changeStatus(3, values.reason);
        },
    });

    const changeStatus = async (s: number, reason: string) => {
        setStatus(s);
        if ((s == 2) || (s == 3 && reason.length > 0)) {
            const response = await fetch(process.env.REACT_APP_API_URL+"/api/event/update?id_event=" + row.Event.Id_Event, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify(
                    {
                        Id_Status: s,
                        Reason: s == 3 ? reason : null
                    }
                ),
            });
            if (response.ok) {
                setSnack({ message: t("Snackbar.success"), type: "success", open: true });
            } else {
                setSnack({ message: t("Snackbar.error"), type: "error", open: true });
            }
            onClose();
        }
    };

    return (
        <>
            <MuiDialog open={true} onClose={onClose} className='dialog' classes={{ paper: 'dialog_img' }} fullWidth>
                <DialogTitle><CloseIcon className="close" onClick={onClose}/><Typography variant="h2" align="center">{t("StatusDialog.label")}</Typography></DialogTitle>
                {status != 3 ?
                    <DialogContent >
                        <Paper className='dialog_paper_center center'>
                            <AvatarGenerator size='big' name={row.DisplayName} />
                            <Typography variant="h3" align="center" sx={{ margin: "20px" }}>{row.DisplayName}</Typography>
                            <div className={'type_div' + row.Event.Id_Type}>
                                <Typography variant='h4'>{t('Vacation.vac' + row.Event.Id_Type)}</Typography>
                            </div>
                            <div className="dialog_row" >
                                <Typography variant="h3">{row.Event.StartDate.substring(0, row.Event.StartDate.indexOf('T'))} - {row.Event.EndDate.substring(0, row.Event.StartDate.indexOf('T'))}</Typography>
                                {days(row, 'h3')}
                            </div>
                            <Typography variant="h3" align="center" sx={{ margin: "10px", marginBottom: "35px", wordWrap: "break-word" }}>{row.Event.Description}</Typography>
                            <DialogContentText>
                                <div className="dialog_submit">
                                    <Button type="submit" variant="contained" onClick={() => changeStatus(3, "")} className="button"><Typography>{t("Button.reject")}</Typography></Button>
                                    <Button type="submit" variant="contained" onClick={() => changeStatus(2, "")} className="button"><Typography>{t("Button.accept")}</Typography></Button>
                                </div>
                            </DialogContentText>
                        </Paper>
                    </DialogContent>
                    :
                    <DialogContent sx={{ marginTop: "3%" }}>
                        <form onSubmit={formik.handleSubmit}>
                            <Paper className='dialog_paper_center center'>
                                <TextField
                                    autoFocus fullWidth
                                    name="reason"
                                    label={t("Attributes.reason") + " *"}
                                    variant="outlined"
                                    className="inputRounded"
                                    value={formik.values.reason}
                                    onChange={formik.handleChange}
                                    error={formik.touched.reason && Boolean(formik.errors.reason)}
                                    helperText={formik.touched.reason && formik.errors.reason}
                                    multiline
                                    rows={3}>
                                </TextField>
                                <div className="dialog_submit">
                                    <Button type="submit" variant="contained" className="button"><Typography>{t("Button.reject")}</Typography></Button>
                                </div>
                            </Paper>
                        </form>
                    </DialogContent>
                }

            </MuiDialog>
        </>
    );
}
export default Dialog;