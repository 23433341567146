import React, { useState, useContext, useEffect } from "react";
import { Paper, Box, InputAdornment, IconButton, Typography, Divider } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { currentUserContext } from "../../components/Models";
import { UserContext } from "../../context/UserContext";
import UserOptions from "../../components/UserOptions";
import Logo from '../../assets/Logo.svg';


const Login = ({handleLanguage, language}: any) => {
    const {user, setUser, message, setMessage} = useContext(UserContext) as currentUserContext;
    const [checked, setChecked] = useState(localStorage.getItem("IsChecked") != null ? JSON.parse(localStorage.getItem("IsChecked")!) : false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const [errorMessage, setErrorMessage] = useState("");
    const {t} = useTranslation("");

    const submitLogin = async (values: any) => {
        const response = await fetch(process.env.REACT_APP_API_URL+"/api/login/token?remember_me=" + checked, {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: JSON.stringify(
                `grant_type=&username=${values.email}&password=${values.password}&scope=&client_id=&client_secret=`
            ),
            credentials: "include"
        });
        const data = await response.json();

        if (!response.ok) {
            setErrorMessage(data.detail);
        } else {
            localStorage.setItem("IsChecked", checked);
            setMessage("repeat");     
        }
    };

    const handleChecked = (e: any) => {
        localStorage.setItem("IsChecked", e.target.checked);
        setChecked(e.target.checked);
    };

    const validationSchema = yup.object({
        email: yup.string()
            .required(t("Form.required")),
        password: yup.string()
            .required(t("Form.required")),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            submitLogin(values);
        },
    });

    return (
        <>
            <Paper elevation={12} className="paper_login">
                <Box className="form_login">
                    <form onSubmit={formik.handleSubmit}>             
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth autoFocus
                            label={t("Login.email") + " *"}
                            name="email"
                            autoComplete="email"
                            className="inputRounded"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="password"
                            label={t("Login.password") + " *"}
                            autoComplete="current-password"
                            className="inputRounded"
                            type={showPassword ? "text" : "password"}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />                             
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={checked}
                                                   onChange={handleChecked}/>}
                                label={t("Login.rememberMe")}
                            />   
                            <Typography variant='h6' className="verical_center error" >{errorMessage}</Typography>                   
                            <Button type="submit" fullWidth variant="contained" className="button">
                                {t("Login.login")}
                            </Button>
                            <Divider className="divider"><Typography variant="h4">{t("Login.orLogInWith")}</Typography></Divider>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                className="button"
                                href={process.env.REACT_APP_AUTH_REDIRECT}
                            >
                                {t("Login.companyAccount")}
                            </Button>                                                     
                    </form>
                </Box>
                <div className="img_login_block" style={{backgroundImage: `url(${require("../../assets/Login.png")})`}}>
                    <div className="login_user_options">
                        <UserOptions handleLanguage={handleLanguage} language={language} unauthenticated/>
                    </div>
                    <div className="verical_center center_logo">
                        <div className="circle_login">
                            <img src={Logo} className="img_logo"/>
                        </div>
                    </div>
                </div>
            </Paper>
        </>
    );

}
export default Login