import React, { createContext, useContext, useState} from "react";
import { Snackbar as MuiSnackbar, Grow} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { snackbar } from "../components/Models";

export const SnackbarContext = createContext<any>(null);

export const SnacbarProvider = (props: any) => {
  const [snack, setSnack] = useState<snackbar>({ message: '', type: 'success', open: false });

  function GrowTransition(props: any) {
    return <Grow {...props} />;
  } 

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props,ref,) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <>
      <SnackbarContext.Provider value={{setSnack}}>
      {props.children}
        <MuiSnackbar open={snack.open} autoHideDuration={3000} TransitionComponent={GrowTransition}
        onClose={()=>setSnack({ message: '', type: 'success', open: false })}>
          <Alert severity={snack.type}>
            {snack.message}
          </Alert>
        </MuiSnackbar>
      </SnackbarContext.Provider>
    </>
  );
};

