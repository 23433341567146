import React, { useState, useEffect, useContext } from 'react'
import { Table, TableBody, TableCell, Paper, TableFooter, Pagination, TableRow, TextField, TableHead } from '@mui/material';
import { Typography, InputAdornment, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import SearchIcon from '@mui/icons-material/Search';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from '../../context/SnackbarContext';
import AvatarGenerator from '../../components/AvatarGenerator';
import { currentUserContext } from "../../components/Models";
import { UserContext } from "../../context/UserContext";
import { userRes } from '../../components/Models';

const UsersList = ({ onClose }: any) => {
    const { user, setUser, message, setMessage } = useContext(UserContext) as currentUserContext;
    const { setSnack } = useContext(SnackbarContext);
    const [users, setUsers] = useState<userRes[]>([]);
    const [usersAll, setUsersAll] = useState<userRes[]>([]);
    const [page, setPage] = useState<number>(1);
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation("");
    const PageSize = 12;

    const fetchUsers = async () => {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/user/users_short", {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include"
        });
        const res = await response.json();
        const resSearch = res.filter((user: userRes) => user.DisplayName.toLowerCase().includes(search.toLowerCase()))
        setUsersAll(resSearch);
        const lastId = page * PageSize;
        const firstId = lastId - PageSize;
        setUsers(resSearch.slice(firstId, lastId));
    };

    useEffect(() => {
        fetchUsers();
    }, [, search, page, loading]);

    const handleHasPaidLeave = (row: userRes, value: boolean) => {
        return patchUser(row, "HasPaidLeave", value)
    }
    const handleEarnedPaidDays = (row: userRes, value: number) => {
        return patchUser(row, "EarnedPaidDays", value)
    }
    const handleYearlyPaidDays = (row: userRes, value: number) => {
        return patchUser(row, "YearlyPaidDays", value)
    }
    const handleOnDemandDays = (row: userRes, value: number) => {
        return patchUser(row, "OnDemandDays", value)
    }

    const patchUser = async (row: userRes, change: string, value: number | boolean) => {
        setLoading(true);
        //@ts-ignore
        if (value >= 0 || change=="HasPaidLeave") {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/user/" + row.Id_User, {
                method: "PATCH",
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(
                    {
                        [change]: value
                    }
                ),
            });
            if (response.ok) {
                setSnack({ message: t("Snackbar.success"), type: "success", open: true });
            } else {
                setSnack({ message: t("Snackbar.error"), type: "error", open: true });
            }
            //@ts-ignore
        } else if(value<0 || !isNaN(+value)){
            setSnack({ message: t("Snackbar.leave_value_error"), type: "error", open: true });
        }
        if (row.Id_User == user?.User.Id_User) {
            setMessage("self_update");
        }
        fetchUsers();
    };

    useEffect(() => {
        setPage(1);
    }, [search]);

    return (
        <>
            <Dialog open={true} onClose={onClose} className='dialog' classes={{ paper: 'dialog_img' }} fullWidth>
                <DialogTitle ><CloseIcon className="close" onClick={onClose} />
                    <Typography variant="h2" align="center">{t("UsersList.label")}</Typography>
                </DialogTitle>
                <DialogContent className="nopad">
                    <Paper className="dialog_paper_wide center">
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell> <TextField
                                        label={t("UsersList.search")}
                                        variant="standard"
                                        margin="normal"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                        }}
                                    ></TextField></TableCell>
                                    <TableCell align="center"><Typography variant='h5'>{t("UsersList.tableHeader1")}</Typography></TableCell>
                                    <TableCell align="center"><Typography variant='h5'>{t("UsersList.tableHeader2")}</Typography></TableCell>
                                    <TableCell align="center"><Typography variant='h5'>{t("UsersList.tableHeader3")}</Typography></TableCell>
                                    <TableCell align="center"><Typography variant='h5'>{t("UsersList.tableHeader4")}</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((row) => (
                                    <TableRow sx={{ "& td": { border: 0 } }} className="row" key={row.Id_User}>
                                        <TableCell sx={{ maxWidth: '400px' }}>
                                            <div className='table_user user'>
                                                <AvatarGenerator name={row.DisplayName} /><Typography variant='h5'>{row.DisplayName}</Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControlLabel control={<Checkbox sx={{ marginLeft: 3 }} defaultChecked={row.HasPaidLeave} onChange={(e, value) => handleHasPaidLeave(row, value)} />} label="" />
                                        </TableCell>
                                        <TableCell align="center">
                                            <TextField variant="standard" inputProps={{ min: 0, max: 999 }} type="number" disabled={!row.HasPaidLeave} defaultValue={Math.floor(row.EarnedPaidDays) - row.UsedPaidDays} onChange={(e) => handleEarnedPaidDays(row, (parseInt(e.target.value) + row.UsedPaidDays))} className='user_list' ></TextField>
                                        </TableCell>
                                        <TableCell align="center">
                                            <TextField variant="standard" inputProps={{ min: 0, max: 999 }} type="number" disabled={!row.HasPaidLeave} defaultValue={row.YearlyPaidDays} onChange={(e) => handleYearlyPaidDays(row, parseInt(e.target.value))} className='user_list' ></TextField>
                                        </TableCell>
                                        <TableCell align="center">
                                            <TextField variant="standard" inputProps={{ min: 0, max: 999 }} type="number" defaultValue={row.OnDemandDays} onChange={(e) => handleOnDemandDays(row, parseInt(e.target.value))} className='user_list' ></TextField>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Pagination className='verical_center' count={Math.ceil(usersAll.length / PageSize)} page={page} onChange={(e, value) => { setPage(value) }} />
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Paper>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default UsersList;