import React, { createContext, useEffect, useState } from "react";
import { currentUserContext } from "../components/Models";
import { refreshLogin } from "../components/Methods";

export const UserContext = createContext<currentUserContext | null>(null);

export const UserProvider = (props: any) => {
    const [message, setMessage] = useState("");
    const [user, setUser] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const fetchUser = async () => {
        refreshLogin(false);
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/login/current_login", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include"
        });
        if (!response.ok) {
            var msg = await response.json()
            setMessage(msg.detail);
            setLoaded(true);
        } else {
            setMessage("Logged");
            var User = await response.json()
            refreshLogin(true);
            setUser(User);
            setLoaded(true);
        }
    };

    useEffect(() => {
        if (message == "repeat") {
            fetchUser();
        }
    }, [message]);

    useEffect(() => {
        fetchUser();
    }, []);


    return (
        <>
            <UserContext.Provider value={{ user, setUser, message, setMessage }}>
                {loaded ?
                    props.children
                    : null
                }
            </UserContext.Provider>
        </>
    );
};