import React, { useState, useContext, useEffect } from "react";
import { Dialog as MuiDialog, DialogContent, DialogContentText, DialogTitle, Typography, Button, TextField, Paper } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import { addBusinessDays, calcBusinessDaysConv, removeOverlap } from '../../components/Methods';
import { SnackbarContext } from '../../context/SnackbarContext';
import { dialog, vacationType } from '../../components/Models';
import CloseIcon from '@mui/icons-material/Close';
import Umbrella from "../../assets/umbrella.svg";
import Case from "../../assets/case.svg";
import Pig from "../../assets/pig.svg";
import { Dataset } from "@mui/icons-material";

const Dialog = ({ onClose, user, language, action, row }: dialog) => {
    const { setSnack } = useContext(SnackbarContext);
    const [type, setType] = useState(row != undefined ? row.Event.Id_Type : vacationType.Basic);
    const [start, setStart] = useState<string>("");
    const [end, setEnd] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [msg, setMsg] = useState<string>("");
    const { t } = useTranslation();

    const extendEvent = async (End: any, Description: string) => {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/event/extend?id_event=" + row?.Event.Id_Event, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(
                {
                    "Description": Description,
                    "EndDate": End
                }
            ),
        });
        if (response.ok) {
            setSnack({ message: t("Snackbar.success"), type: "success", open: true });
        } else {
            setSnack({ message: t("Snackbar.error"), type: "error", open: true });
        }
        onClose();
    };

    const submitEvent = async (Start: string, End: string, Description: string, Type: number) => {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/event", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(
                {
                    "Description": Description,
                    "StartDate": Start,
                    "EndDate": End,
                    "IsPaid": Type != 1 ? true : false,
                    "Id_Type": Type,
                    "Id_Status": 1,
                    "Id_User": user!.User.Id_User
                }
            ),
        });
        if (response.ok) {
            setSnack({ message: t("Snackbar.success"), type: "success", open: true });
        } else {
            setSnack({ message: t("Snackbar.error"), type: "error", open: true });
        }
        onClose();
    };

    async function Submit(start: string, end: string, description: string) {
        let [newStart, newEnd] = await removeOverlap(start, end, user);
        if (newStart == null && newEnd == null) {
            setMsg(t("Dialog.message5"));
            return;
        } else if (newStart == null && newEnd != null) {
            setMsg(t("Dialog.message4" + action));
        }
        if (newStart != null) {
            const days = calcBusinessDaysConv(newStart, newEnd);
            if (days == 0) {
                setMsg(t("Dialog.message6"));
                return;
            }
            if (((type == 2) && (days > (user!.User.EarnedPaidDays - user!.User.UsedPaidDays))) || ((type == 3) && (days > (user!.User.OnDemandDays - user!.User.UsedOnDemandDays)))) {
                setStart(newStart);
                setEnd(newEnd);
                setMsg(t("Dialog.message2"));
                return;
            }
            if (action == 'add') {
                submitEvent(newStart, newEnd, description, type);
            } else {
                const End=JSON.parse(JSON.stringify(moment(newEnd).subtract(1, 'day')))
                //@ts-ignore
                if (!(End.substring(0, End.indexOf('T')) > row?.Event.EndDate.substring(0, row?.Event.EndDate.indexOf('T')))) {
                    setMsg(t("Dialog.message7"));
                    return;
                } else {
                    extendEvent(End, description);
                }
            }
        }

    };

    const extendSchema = yup.object({
        start: yup.date()
            .required(t("Form.required")),
        end: yup.date()
            .required(t("Form.required"))
            .min(yup.ref('start'),
                t("Dialog.message1")),
        description: yup.string()
            .max(255, t("Form.toLong"))
    });

    const addSchema = extendSchema.shape({
        start: yup.date()
            .required(t("Form.required"))
            .min(new Date(),
                t("Dialog.message3")),
    });

    const formik = useFormik({
        initialValues: {
            start: row != undefined ? moment(row.Event.StartDate) : '',
            end: row != undefined ? moment(row.Event.EndDate) : '',
            description: '',
        },
        validationSchema: action == "add" ? addSchema : extendSchema,
        onSubmit: (values) => {
            const dateStart = JSON.parse(JSON.stringify(moment(JSON.parse(JSON.stringify(values.start))).add(1, 'days')));
            const dateEnd = JSON.parse(JSON.stringify(moment(JSON.parse(JSON.stringify(values.end))).add(1, 'days')));
            setStart(dateStart);
            setEnd(dateEnd);
            setDescription(values.description);
            Submit(dateStart, dateEnd, values.description);
        },
    });

    const handleAgree = () => {
        let paidEnd
        if (type == 2) {
            paidEnd = addBusinessDays(start, user!.User.EarnedPaidDays - user!.User.UsedPaidDays);
        } else {
            paidEnd = addBusinessDays(start, user!.User.OnDemandDays - user!.User.UsedOnDemandDays);
        }
        submitEvent(start, JSON.parse(JSON.stringify(paidEnd)), description, type);
        submitEvent(JSON.parse(JSON.stringify(paidEnd.add(1, 'days'))), end, description, 1);
        onClose();
    }

    return (
        < >
            {msg.length < 50 ?
                <MuiDialog open={true} onClose={onClose} className='dialog' classes={{ paper: 'dialog_img' }} fullWidth>
                    <form onSubmit={formik.handleSubmit}>
                        <DialogTitle><CloseIcon className="close" onClick={onClose} /><Typography variant="h2" align="center">{action == "add" ? t("Dialog.labelAdd") : t("Dialog.labelExtend")}</Typography></DialogTitle>
                        <DialogContent className="nopad">
                            <DialogContentText>
                                <div className='div_dialog'>
                                    <div className={(action == "extend" && row?.Event.Id_Type != vacationType.Basic) ? "opacity" : ""} >
                                        <Button onClick={() => { setType(vacationType.Basic) }} disabled={(action == "extend" && row?.Event.Id_Type != vacationType.Basic) ? true : false} disableRipple>
                                            <div className={type == vacationType.Basic ? 'vac_info1_small active' : 'vac_info1_small'}>
                                                <img src={Umbrella} className="vac_icon_dialog" />
                                                <div className='div_text_dialog'>
                                                    <Typography variant="h4" align="left" className="font">{t('Vacation.vacation1')}</Typography>
                                                    <Typography variant="h4" align="left" className="font">{user!.User.UsedUnpaidDays}</Typography>
                                                </div>
                                            </div>
                                        </Button>
                                    </div>
                                    <div className={(user!.User.EarnedPaidDays == 0 || (action == "extend" && row?.Event.Id_Type != vacationType.Paid)) ? "opacity" : ""}>
                                        <Button onClick={() => { setType(vacationType.Paid) }} disableRipple disabled={(user!.User.EarnedPaidDays == 0 || (action == "extend" && row?.Event.Id_Type != vacationType.Paid)) ? true : false}>
                                            <div className={type == vacationType.Paid ? 'vac_info2_small active' : 'vac_info2_small'}>
                                                <img src={Pig} className="vac_icon_dialog" />
                                                <div className='div_text_dialog'>
                                                    <Typography variant="h4" align="left" className="font">{t('Vacation.vacation2')}</Typography>
                                                    <Typography variant="h4" align="left" className="font" >{Math.floor(user!.User.EarnedPaidDays)!=0? (user!.User.UsedPaidDays+"/"+Math.floor(user!.User.EarnedPaidDays)):t("UserPanel.None")}</Typography>
                                                </div>
                                            </div>
                                        </Button>
                                    </div>
                                    <div className={(user!.User.OnDemandDays == 0 || (action == "extend" && row?.Event.Id_Type != vacationType.OnDemand)) ? "opacity" : ""}>
                                        <Button onClick={() => { setType(vacationType.OnDemand) }} disableRipple disabled={(user!.User.OnDemandDays == 0 || (action == "extend" && row?.Event.Id_Type != vacationType.OnDemand)) ? true : false}>
                                            <div className={type == vacationType.OnDemand ? 'vac_info3_small active' : 'vac_info3_small'}>
                                                <img src={Case} className="vac_icon_dialog" />
                                                <div className='div_text_dialog'>
                                                    <Typography variant="h4" align="left" className="font">{t('Vacation.vacation3')}</Typography>
                                                    <Typography variant="h4" align="left" className="font">{user!.User.OnDemandDays!=0? user!.User.UsedOnDemandDays+"/"+user!.User.OnDemandDays:t("UserPanel.None")}</Typography>
                                                </div>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                                <Paper className="dialog_paper center">
                                    <div className="datepicker">
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language.language} >
                                            <DatePicker
                                                label={t("Dialog.start") + " *"}
                                                disabled={action != "add"}
                                                className="inputRounded"
                                                value={formik.values.start}
                                                onChange={(value) => {
                                                    formik.setFieldValue("start", value);
                                                }}
                                                renderInput={(params: any) =>
                                                    <TextField {...params} sx={{ maxWidth: "200px" }}
                                                        error={formik.touched.start && Boolean(formik.errors.start)}
                                                        helperText={formik.touched.start && formik.errors.start} />}
                                                inputFormat="YYYY-MM-DD"
                                            />
                                            <Typography variant="h3" align="center" sx={{ margin: "14px" }}>-</Typography>
                                            <DatePicker
                                                label={t("Dialog.end") + " *"}
                                                className="inputRounded"
                                                value={formik.values.end}
                                                onChange={(value) => {
                                                    formik.setFieldValue("end", value);
                                                }}
                                                renderInput={(params: any) =>
                                                    <TextField {...params} sx={{ maxWidth: "200px" }}
                                                        error={formik.touched.end && Boolean(formik.errors.end)}
                                                        helperText={formik.touched.end && formik.errors.end} />}
                                                inputFormat="YYYY-MM-DD"
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <TextField
                                        label={t("Attributes.description")}
                                        variant="outlined"
                                        className="inputRounded dialog_description"
                                        name="description"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        error={formik.touched.description && Boolean(formik.errors.description)}
                                        helperText={formik.touched.description && formik.errors.description}
                                        multiline
                                        rows={2}>
                                    </TextField>
                                    {msg.length > 1 ?
                                        <Typography className="dialog_msg">{msg}</Typography>
                                        : null}
                                    <div className="dialog_submit">
                                        <Button type="submit" variant="contained" className="button" >{action == "add" ? t("Button.application") : t("Button.extend")}</Button>
                                    </div>
                                </Paper>
                            </DialogContentText>
                        </DialogContent>
                    </form>
                </MuiDialog>
                :
                <MuiDialog open={true} onClose={onClose} className='dialog' fullWidth >
                    <DialogContent className="dialog_paper center">
                        <Typography variant="subtitle1" align="center" sx={{ marginTop: "10px", color: "black", width: "500px" }}>{msg}</Typography>
                        <div className="dialog_submit">
                            <Button variant="contained" className="button" onClick={handleAgree}>{t("Button.confirm")}</Button>
                        </div>
                    </DialogContent>
                </MuiDialog>
            }
        </ >

    );
}

export default Dialog;