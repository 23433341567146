import {Typography} from '@mui/material'
import i18next from 'i18next';
import moment from 'moment';
import {eventRes, currentUser, vacationStatus} from '../components/Models';
import {Variant} from '@mui/material/styles/createTypography';

export function calcBusinessDaysConv(startDate: string, endDate: string) {
    return calcBusinessDays(startDate.substring(0, startDate.indexOf('T')), endDate.substring(0, startDate.indexOf('T')));
}

export function calcBusinessDays(startDate: string, endDate: string) {
    const day = moment(startDate);
    let businessDays = 0;
    while (day.isSameOrBefore(endDate, 'day')) {
        if (day.day() !== 0 && day.day() !== 6) {
            businessDays++;
        }
        day.add(1, 'd');
    }
    return businessDays;
}

export function addBusinessDays(originalDate: string, numDaysToAdd: number) {
    let daysRemaining = numDaysToAdd - 1;
    const day = moment(originalDate);
    while (daysRemaining > 0) {
        day.add(1, 'days');
        if (day.day() !== 0 && day.day() !== 6) {
            daysRemaining--;
        }
    }
    return day;
}

export async function removeOverlap(startDate: string, endDate: string, user: currentUser | null) {
    const response = await fetch(process.env.REACT_APP_API_URL+ "/api/event/search?user_id=" + user?.User.Id_User, {
        method: "GET",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: "include"
    });
    let res = await response.json();
    let resFilter = res.filter((event: eventRes) => event.Event.Id_Status != vacationStatus.Rejected && event.Event.Id_Status != vacationStatus.Resign);
    let overlap: null | string[] = null;
    let overlap2 = false;
    resFilter.map((event: eventRes) => {
        if ((endDate < event.Event.StartDate) || (startDate > event.Event.EndDate)) {
        } else {
            if (overlap != null) {
                overlap2 = true;
            }
            overlap = [event.Event.StartDate, event.Event.EndDate];
        }
    });
    if (overlap != null && overlap2 == false) {
        let difference
        //1 day in milliseconds
        var one_day = 1000 * 60 * 60 * 24;
        if (startDate >= overlap[0] && startDate <= overlap[1]) {
            if (endDate < overlap[1]) {
                return [null, null]
            }
            difference = new Date(overlap[1]).getTime() - new Date(startDate).getTime()
            difference = Math.round(difference / one_day) + 1
            let newDate = moment(startDate).add(difference, "days")
            return [JSON.parse(JSON.stringify(newDate)), endDate];
        } else if (endDate >= overlap[0] && endDate <= overlap[1]) {
            if (startDate > overlap[0]) {
                return [null, null];
            }
            difference = new Date(endDate).getTime() - new Date(overlap[0]).getTime()
            difference = Math.round(difference / one_day) + 1
            let newDate = moment(endDate).subtract(difference, "days")
            return [startDate, JSON.parse(JSON.stringify(newDate))];
        }else if(startDate < overlap[0] && endDate > overlap[1]){
            return [null, null];
        }
    }
    if (overlap2) {
        return [null, "overlap2"];
    } else {
        return [startDate, endDate];
    }
}

export function refreshLogin(repeat: boolean) {
    const refresh = async () => {
        await fetch(process.env.REACT_APP_API_URL+"/api/login/refresh?remember_me=" + (localStorage.getItem("IsChecked") == null ? "false" : localStorage.getItem("IsChecked") ), {
            method: "PUT",
            headers:
                {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
            ,
            credentials: "include"
        });
    }
    if (repeat) {
        setInterval(() => {
            refresh();
        }, 840000);
    } else {
        refresh();
    }
}

export const days = (row: eventRes, variant: Variant) => {
    const days = calcBusinessDays(row.Event.StartDate, row.Event.EndDate);

    return (
        <Typography variant={variant}>{days + " " + i18next.t(daysStr(days))}</Typography>
    )
}

export const daysCirc = (value:number, variant: Variant) => {

    return (
        <Typography variant={variant}>{value + " " + i18next.t(daysStr(value))}</Typography>
    )
}

const daysStr = (days:number) => {
    let daysStr;
    if (days == 1) {
        daysStr = "Attributes.day"
    } else {
        daysStr = "Attributes.days"
    }

    return daysStr
}





