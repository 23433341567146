import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

const NotFound = () => {
    const navigate = useNavigate();
    const {t} = useTranslation("");

    const backHome = () => {
        navigate("/");
    }

    return (
        <Box className="notFound verical_center">       
            <Typography variant="h1" style={{ color: 'white' }}>
                404
            </Typography>
            <Typography variant="h6" style={{ color: 'white' }}>
                {t("NotFound.notFound")}
            </Typography>
           <Button variant="contained" onClick={backHome}>{t("NotFound.goBack")}</Button>           
        </Box>
    );
};

export default NotFound;